<template>
  <div class="PersonalCenter">
    <heads></heads>
    <div class="con_wrap">
      <div class="con_box">
        <div class="layui-tab layui-tab-brief" lay-filter="docDemoTabBrief">
          <div class="pc_img">
            <div class="img" :style="{
              backgroundImage: 'url(' + userInfo.avatar + ')',
            }"></div>
            <div class="text">您好，{{ userInfo.account }}</div>
          </div>
          <ul class="layui-tab-title">
            <li class="layui-this">我的信息</li>
            <li>我的课程</li>
            <li>我的报名</li>
            <li>我的订单</li>
            <li>我的考试</li>
            <!-- <li>历史课程</li>
            <li>证书打印</li>
            <li>我的消息</li> -->
          </ul>
          <div class="layui-tab-content">
            <div class="layui-tab-item layui-show xinxi">
              <p class="user-info-title">个人信息</p>
              <el-form ref="userInfo" :model="userInfo" label-width="100px">
                <el-form-item label="姓名">
                  <el-input v-model="userInfo.nickname"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                  <el-radio-group v-model="userInfo.sex">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="手机">
                  <el-input v-model="userInfo.phone"></el-input>
                </el-form-item>
                <el-form-item label="省">
                  <el-input v-model="userInfo.province"></el-input>
                </el-form-item>
                <el-form-item label="市">
                  <el-input v-model="userInfo.city"></el-input>
                </el-form-item>
                <el-form-item label="区">
                  <el-input v-model="userInfo.district"></el-input>
                </el-form-item>
                <el-form-item label="单位名称">
                  <el-input v-model="userInfo.unit_name"></el-input>
                </el-form-item>
                <el-form-item label="详细地址">
                  <el-input v-model="userInfo.address"></el-input>
                </el-form-item>
                <el-form-item label="领导姓名">
                  <el-input v-model="userInfo.leader_name"></el-input>
                </el-form-item>
                <el-form-item label="办公室电话">
                  <el-input v-model="userInfo.office_phone"></el-input>
                </el-form-item>
                <el-form-item label="联系人">
                  <el-input v-model="userInfo.contacts"></el-input>
                </el-form-item>
                <el-form-item label="邮箱">
                  <el-input v-model="userInfo.email"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmit">保存修改</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="layui-tab-item">
              <div class="ke_item" v-for="(item, index) in empowerCourse" :key="index">
                <div class="item_left">
                  <img :src="item.copy" alt="" />
                </div>
                <div class="item_center">
                  <div class="course-name">
                    <p>{{ item.course_name }}</p>
                  </div>
                  <div class="course-progress">
                    课程进度：<el-progress :percentage="item.percentage"></el-progress>
                  </div>
                  <div>
                    学习时间：{{ item.shelf_time }}­­­­~{{ item.obtain_time }}
                  </div>
                </div>
                <div class="item_right">
                  <router-link :to="{
                    path: path,
                    query: {
                      id: item.course_id,
                      division_id: item.division_id,
                    },
                  }" type="primary" plain :class="item.isShelf == 1 && item.isObtain == 0
                    ? ''
                    : 'layui-btn-disabled'
                    ">开始学习
                  </router-link>
                </div>
              </div>
            </div>
            <div class="layui-tab-item">
              <div class="signup">
                <ul class="list-title-ul">
                  <li>姓名</li>
                  <li>报名项</li>
                  <li>审核状态</li>
                  <li>操作</li>
                </ul>
                <div class="signup-content">
                  <ul class="list-content-ul">
                    <li v-for="(item, index) in signups" :key="index">
                      <span>{{ item.name }}</span>
                      <span>
                        {{ signupType(item.type, item.train_stage_id) }}</span>
                      <span>{{ auditingStatus(item.auditing_status) }} </span>

                      <span v-if="item.auditing_status == 0"> 审核中... </span>
                      <span v-if="
                        item.type == 1 &&
                        item.order != null &&
                        item.order.pay_status == 1 &&
                        item.auditing_status == 1
                      ">
                        <router-link type="primary" plain :to="{
                          path: '/payment',
                          query: { id: item.order.id },
                        }">
                          去支付
                        </router-link>
                      </span>
                      <span v-if="
                        item.type == 1 &&
                        item.order != null &&
                        item.order.pay_status == 2 &&
                        item.auditing_status == 1
                      ">已支付</span>
                      <span v-if="
                        item.type == 1 &&
                        item.order != null &&
                        item.order.pay_status == 3 &&
                        item.auditing_status == 1
                      ">支付失败</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="layui-tab-item">
              <div class="order">
                <ul class="list-title-ul">
                  <li>订单信息</li>
                  <li>金额</li>
                  <li>状态</li>
                  <li>操作</li>
                </ul>
              </div>

              <div class="order-content">
                <ul class="list-content-ul">
                  <li v-for="(item, index) in orders" :key="index">
                    <span>
                      <div class="name">{{ item.detail }}</div>
                      <div>单号：{{ item.out_trade_no }}</div>
                      <div class="time">下单时间：{{ item.create_time }}</div>
                    </span>
                    <span class="price">￥{{ item.total_fee }}</span>
                    <span>{{ payStatus(item.pay_status, item.cancel_status) }}
                    </span>
                    <span v-if="item.pay_status == 1 && item.cancel_status == 1">
                      <router-link type="primary" plain :to="{
                        path: '/payment',
                        query: { id: item.id },
                      }">
                        去支付
                      </router-link>
                    </span>

                    <span v-if="item.pay_status == 2 && item.cancel_status == 1">
                      已支付
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="layui-tab-item">
              <div class="order">
                <ul class="list-title-ul">
                  <li>试卷编号</li>
                  <li>总分</li>
                  <li>得分</li>
                  <li>操作</li>
                </ul>
              </div>
              <div class="signup-content">
                <ul class="list-content-ul">
                  <li v-for="(item, index) in userExamPapers" :key="index">
                    <span>{{ item.id }}</span>
                    <span>{{ item.score }}分</span>
                    <span>{{ item.user_score }}分</span>
                    <span>
                      <button class="layui-btn layui-btn-radius" type="primary" plain
                        @click="scanUserExamPaper(item.id)">
                        查看详情
                      </button>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <!-- <div class="layui-tab-item">历史课程</div>
            <div class="layui-tab-item">证书打印</div>
            <div class="layui-tab-item">我的消息</div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="show-user-exam">
      <form class="layui-form" onSubmit="return false">
        <div class="exam">
          <div class="exam-list" v-for="(exams, indexs) in userExams" :key="indexs" :id="indexs + 1">
            <div class="exam-title">
              {{ indexs + 1 }}. {{ exams.name }} {{ exams.score }}分
            </div>

            <div class="exam-picture" v-if="exams.images.length > 0">
              <img v-for="(images, images_index) in exams.images" :key="images_index"
                class="img-thumbnail img-responsive" :src="images.image" />
            </div>

            <div class="exam-chose" v-for="(chose, index) in exams.chose" :key="index">
              <div class="exam-chose-item">
                <input type="radio" :name="examChoseName(exams.exam_id, indexs)" :value="examChoseValue(index)"
                  :title="examChoseTitle(index, chose.name)" :examid="exams.id" :index="indexs + 1"
                  :checked="examChoseIsChecked(index, exams.uanswer)" />
              </div>
            </div>
            <br />
            <div class="layui-input-block">
              <b class="sc-color">用户答案：</b>
              {{ exams.uanswer }}
            </div>
            <div class="layui-input-block">
              <b class="sc-color">正确答案：</b>
              {{ exams.answer }}
            </div>
            <div class="layui-input-block">
              <b class="sc-color">是否正确：</b>
              {{ exams.is_correct }}
            </div>
            <div class="layui-input-block">
              <b class="sc-color">试题解析：</b>
              <p v-html="exams.answer_analysis"></p>
            </div>
          </div>
        </div>
      </form>
    </div>

    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import $ from "jquery";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import {
  getUserInfo,
  getempowerCourse,
  reset,
  getSignups,
  getOrders,
  getUserExamPapers,
  getScanUserExamPaper,
} from "@api/user";
export default {
  components: {
    heads,
    foot,
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  data() {
    return {
      userInfo: {},
      empowerCourse: [],
      path: "",
      background: "",
      resetUserInfo: {
        nickname: "",
        phone: "",
        sex: "",
        province: "",
        city: "",
        district: "",
        unit_name: "",
        address: "",
        leader_name: "",
        office_phone: "",
        contacts: "",
        email: "",
      },
      signups: [],
      orders: [],
      userExamPapers: [],
      userExams: [],
    };
  },
  methods: {
    format(percentage) {
      return percentage === 100 ? "满" : `${percentage}%`;
    },
    getUserInfo() {
      let that = this;
      getUserInfo().then(function (res) {
        that.$set(that, "userInfo", res.data);
      });
    },
    onSubmit() {
      let that = this;
      this.resetUserInfo.nickname = this.userInfo.nickname;
      this.resetUserInfo.phone = this.userInfo.phone;
      this.resetUserInfo.sex = this.userInfo.sex;
      this.resetUserInfo.province = this.userInfo.province;
      this.resetUserInfo.city = this.userInfo.city;
      this.resetUserInfo.district = this.userInfo.district;
      this.resetUserInfo.unit_name = this.userInfo.unit_name;
      this.resetUserInfo.address = this.userInfo.address;
      this.resetUserInfo.leader_name = this.userInfo.leader_name;
      this.resetUserInfo.office_phone = this.userInfo.office_phone;
      this.resetUserInfo.contacts = this.userInfo.contacts;
      this.resetUserInfo.email = this.userInfo.email;
      reset(this.resetUserInfo)
        .then(function ({ data }) {
          that.getUserInfo();
          that.$dialog.success(data.message);
        })
        .catch(function (e) {
          that.$dialog.error(e.msg);
        });
    },

    signupType(type, train_stage_id) {
      if (type == 1) {
        return ["初级", "中级", "高级"][train_stage_id - 1] + "培训课程";
      }
    },

    auditingStatus(auditing_status) {
      return ["审核中...", "已审核通过", "审核未通过"][auditing_status];
    },

    payStatus(payStatus, cancelStatus) {
      if (payStatus == 1 && cancelStatus == 1) {
        return "待支付";
      } else if (payStatus == 2 && cancelStatus == 1) {
        return "已支付";
      } else if (payStatus == 3 && cancelStatus == 1) {
        return "订单关闭";
      } else {
        return "订单取消";
      }
    },

    scanUserExamPaper(userExamPaperId) {
      let that = this;
      getScanUserExamPaper({ id: userExamPaperId }).then(function (res) {
        that.$set(that, "userExams", res.data);
      });
      layui.use(["layer", "jquery"], function () {
        var layer = layui.layer,
          $ = layui.jquery;
        layer.open({
          title: "答题详情",
          type: 1,
          closeBtn: 1,
          anim: 2,
          area: ["90%", "90%"],
          shadeClose: true,
          content: $(".show-user-exam"),
          skin: "layui-layer-lan",
          end: function () {
            that.userExams = [];
            $(".show-user-exam").hide();
          },
        });
      });
    },

    examChoseName: function (examId, index) {
      return "chose[" + examId + "][" + parseInt(index + 1) + "]";
    },

    examChoseValue: function (index) {
      return String.fromCharCode(index + 65);
    },
    examChoseTitle: function (index, name) {
      return String.fromCharCode(index + 65) + "." + name;
    },

    examChoseIsChecked: function (index, uanswer) {
      if (String.fromCharCode(index + 65) == uanswer) {
        return "checked";
      } else {
        return "";
      }
    },
  },
  mounted: function () {
    let that = this;
    window.onresize = function () {
      return (function () {
        that.$router.go(0);
      })();
    };
    that.getUserInfo();
    getempowerCourse().then(function (res) {
      that.$set(that, "empowerCourse", res.data);
    });
    getSignups().then(function (res) {
      that.$set(that, "signups", res.data);
      console.log(res.data);
    });
    getOrders().then(function (res) {
      that.$set(that, "orders", res.data);
    });

    getUserExamPapers().then(function (res) {
      that.$set(that, "userExamPapers", res.data);
    });
  },
  created: function () {
    this.path = this.$route.meta.path;
    document.title = this.$route.meta.title;
  },
  updated: function () {
    layui.use(["form"], function () {
      let form = layui.form;
      form.render();
    });
  },

  destroyed: function () {
    layui.use(["layer"], function () {
      let layer = layui.layer;
      layer.closeAll();
    });
    $(".layui-layer-shade").remove();
  },
};

layui.use("element", function () {
  var $ = layui.jquery,
    element = layui.element;
  $(".site-demo-active").on("click", function () {
    var othis = $(this),
      type = othis.data("type");
    active[type] ? active[type].call(this, othis) : "";
  });
});
</script>

<style>
.el-progress-bar {
  width: 300px;
}

.PersonalCenter .con_wrap {
  width: 100%;
  height: 1819px;
  background: url(../assets/images/geren.jpg);
  background-size: cover;
  background-position: center 0;
}

.PersonalCenter .con_box {
  width: 1330px;
  height: 1317px;
  position: relative;
  top: 416px;
  margin: auto;
}

.con_box>.layui-tab {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
}

.con_box>.layui-tab>.layui-tab-title {
  border: 0;
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 100%;
  position: relative;
  top: 300px;
  left: -240px;
}

.con_box>.layui-tab>.layui-tab-title>li {
  padding-bottom: 70px;
  font-size: 18px;
  position: relative;
}

.con_box>.layui-tab>.layui-tab-title>.layui-this {
  color: rgb(8, 117, 26);
}

.con_box>.layui-tab>.layui-tab-title>.layui-this::after {
  border: 0;
  content: "*";
  color: rgb(8, 117, 26);
  position: absolute;
  top: 3px;
  left: -50px;
}

.con_box>.layui-tab>.layui-tab-title>.layui-this::before {
  content: "*";
  color: rgb(8, 117, 26);
  position: absolute;
  top: 3px;
  right: 70px;
}

.con_box>.layui-tab>.layui-tab-content {
  width: 945px;
  height: 1000px;
  position: relative;
  left: -100px;
  padding: 100px 30px;
}

.pc_img {
  width: 380px;
  height: 400px;
  position: relative;
}

.pc_img>.img {
  width: 122px;
  height: 116px;
  border-radius: 50%;
  position: absolute;
  top: 101px;
  left: 125px;
  background-size: cover;
  background-position: center 0;
}

.pc_img>.text {
  width: 200px;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  top: 245px;
  left: 140px;
}

.layui-tab-item {
  width: 100%;
  height: 100%;
}

.ke_item {
  width: 100%;
  height: 100px;
  display: flex;
  margin: 20px 0;
  border-radius: 10px;
}

.item_left>img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.item_center {
  flex: 1;
  padding-left: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item_center>.course-name,
.item_center>.course-progress {
  margin-bottom: 10px;
}

.item_center>.course-name>p {
  font-size: 22px;
  font-family: HYQuanTangShiJ;
}

.item_center>div {
  display: flex;
  font-size: 16px;
}

.item_right {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
}

.xinxi>p {
  display: flex;
  justify-content: center;
  font-size: 22px;
}

.xinxi li {
  width: 100%;
  height: 80px;
  line-height: 80px;
}

input {
  font-size: 16px;
  width: 200px;
  margin-right: 90px;
  height: 25px;
}

.layui-btn-disabled {
  background: #ccc !important;
  color: #fff !important;
  border-color: #ccc !important;
  pointer-events: none !important;
  cursor: default !important;
  opacity: 0.6 !important;
}

.item_right>a,
.list-content-ul li span a {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #409eff;
  border: 1px solid #409eff;
  background: #ecf5ff;
  border-radius: 5px;
  font-size: 16px;
}

.list-content-ul li span a {
  padding: 5px 15px;
}

.item_right>a:hover {
  border: 1px solid #409eff;
  color: #fff;
  background: #409eff;
}

.user-info-title {
  margin-bottom: 20px;
}

.signup {
  position: relative;
  width: 100%;
}

.list-title-ul,
.list-content-ul {
  position: inherit;
  height: 50px;
}

.list-title-ul li {
  position: inherit;
  float: left;
  width: 25%;
  text-align: center;
  line-height: 50px;
  font-size: 24px;
  font-family: HYQuanTangShiJ;
}

.signup .signup-content {
  position: relative;
  margin-top: 15px;
}

.list-content-ul li {
  position: inherit;
  border-bottom: 1px solid #fff;
  padding: 10px 0px !important;
}

.list-content-ul li+li {
  margin-top: 20px;
}

.list-content-ul li span {
  position: relative;
  display: inline-block;
  width: 25%;
  text-align: center;
  font-size: 20px;
  color: #000;
  font-family: 楷体;
  vertical-align: middle;
}

.order-content .list-content-ul li span {
  font-size: 16px;
}

.order-content .list-content-ul li span div {
  word-break: keep-all;
}

.order-content .list-content-ul li span div+div {
  margin-top: 10px;
}

.order-content .list-content-ul li span.price {
  color: #b00;
}

.show-user-exam {
  position: relative;
  padding: 15px;

  display: none;
}

.exam {
  position: relative;
}

.exam .exam-title {
  font-size: 22px;
  font-family: HYQuanTangShiJ;
  display: block;
}

.exam .exam-chose {
  position: relative;
  margin-left: 20px;
  margin-top: 15px;
}

.exam .exam-chose .exam-chose-item+.exam-chose-item {
  position: relative;
  margin-top: 5px;
}

.exam-list+.exam-list {
  margin-top: 15px;
}

.sc-color {
  color: #5d979b;
}

.exam .layui-input-block {
  margin-left: 20px !important;
}

.exam-picture {
  position: relative;
  margin-top: 15px;
}

.exam-picture img {
  display: inline;
  margin-right: 15px;
  margin-bottom: 15px;
}
</style>